import * as React from 'react'
import { IconMail2, IconPhone, IconPlace } from './Icons'
import EmailLink from './EmailLink'
import PhoneLink from './PhoneLink'
import LocationLink from './LocationLink'

const HaveQuestions = ({ heading = 'Have Questions?' }) => {
  return (
    <div className={'mx-auto my-10 flex w-10/12 max-w-[1366px] flex-col'}>
      <h2 className={'my-5 w-full text-center text-4xl uppercase'}>
        {heading || 'Have Questions?'}{' '}
      </h2>
      <p className={'mx-auto max-w-[800px] text-center text-lg'}>
        Our highly trained specialists, coupled with our international sales reach, allow us to
        offer the very best service for your watches and jewelry needs.
      </p>

      <section className={'text-center my-10 flex w-full flex-col gap-5 uppercase md:flex-row '}>
        <LocationLink
          className={ 'mx-auto w-10/12 flex-col items-center md:w-1/3 md:justify-center grid grid-rows-[auto_1fr_1fr]' }>
          <IconPlace className="mx-auto text-5xl text-red-700 " />
          <h3 className=" text-2xl font-bold">Visit Us</h3>
          <p className="">
            9595 Harding Avenue,
            <br />
            Surfside, FL 33154
          </p>
        </LocationLink>

        <PhoneLink
          className={
            'mx-auto w-10/12 flex-col items-center md:w-1/3 md:justify-center grid grid-rows-[auto_1fr_1fr]'
          }
        >
          <IconPhone className="mx-auto text-5xl text-red-700 " />
          <h3 className=" text-2xl font-bold">Call Us</h3>
          <p className="h-full">tel: (305) 865 0999</p>
        </PhoneLink>

        <EmailLink
          className={
            'mx-auto w-10/12 flex-col items-center md:w-1/3 md:justify-center grid grid-rows-[auto_1fr_1fr]'
          }
        >
          <IconMail2 className="mx-auto text-5xl text-red-700" />
          <h3 className=" text-2xl font-bold">EMail Us</h3>
          <p className="h-full">info@grayandsons.com</p>
        </EmailLink>
      </section>
    </div>
  )
}
export default HaveQuestions
