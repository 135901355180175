import React from 'react'

const GiftBoxAnimation = () => {
  return (
    <svg
      role={'img'}
      aria-label={'Floating Giftbox Animation'}
      viewBox="0 0 200 200"
      className="signup-svg"
    >
      <g className="all-gift-box-elements">
        <g className="left-bow">
          <path
            fill="#F8203D"
            d="M77.4,58.5c0,0-29.6-5.2-33-16.5c-3.3-11.3,20.7-21.9,20.7-21.9s-1.2,25.1,26,35L77.4,58.5z"
          />
          <path
            className="gift-box-st2"
            fill="#BF192F"
            d="M65.1,20.1c0,0,10.4-4.6,26,35C91.1,55.1,64.2,46.7,65.1,20.1z"
          />
        </g>
        <g className="center-bow">
          <path
            fill="#F8203D"
            d="M91.1,58.5c0,0-20.8-29.1-14.1-44.6c6.6-15.5,35.9-8.7,35.9-8.7s-22.1,27.6-7.2,60.1L91.1,58.5z"
          />
          <path
            className="gift-box-st2"
            fill="#BF192F"
            d="M112.9,5.3c0,0,12.7,2.8-7.3,60.1C105.6,65.3,89.9,34.7,112.9,5.3z"
          />
        </g>
        <g className="right-bow">
          <path
            className="gift-box-st1"
            fill="#F8203D"
            d="M107,53.4c0,0,4-29.8,15.3-33.7c11.2-3.9,22.9,19.7,22.9,19.7s-25.3,0-34.1,27.6L107,53.4z"
          />
          <path
            className="gift-box-st2"
            fill="#BF192F"
            d="M145.2,39.4c0,0,5.1,10.1-34.1,27.6C111,67,118.4,39.7,145.2,39.4z"
          />
        </g>

        <g className="gift-box">
          <path
            fill="#FFAA00"
            d="M127.8,75L23.9,67.2v93.9c0,1.3,1,2.4,2.3,2.5l17.6,1.4l87.8,7.2v-97L127.8,75z"
          />
          <path
            className="gift-box-st3"
            fill="#FFAA00"
            d="M172.9,162.1l-41.2,10.2v-97l43.1-8.1v92.5C174.8,160.8,174,161.8,172.9,162.1z"
          />
          <path
            className="gift-box-st4"
            fill="#EA8B1B"
            d="M172.8,162.1l-41.1,10.2v-97l43.1-8.1v92.4C174.8,160.8,174,161.8,172.8,162.1z"
          />
          <path
            className="gift-box-st5"
            fill="#F0B030"
            d="M131.7,84V49.4l47.3,9.3c0.8,0.2,1.4,0.9,1.4,1.7v20.7c0,1-0.8,1.7-1.7,1.8L131.7,84z"
          />
          <path
            className="gift-box-st6"
            fill="#FFBB33"
            d="M21.3,84h110.4V49.4L21.2,56c-0.9,0.1-1.7,0.8-1.7,1.8v24.5C19.6,83.2,20.4,84,21.3,84z"
          />
          <polygon
            className="gift-box-st7"
            fill="#E61E38"
            points="60.3,84 60.3,166.4 91.1,168.9 91.1,84 	"
          />
          <polygon
            className="gift-box-st1"
            fill="#F8203D"
            points="60.3,53.7 60.3,84 91.1,84 91.1,51.9 	"
          />
          <polygon
            className="gift-box-st1"
            fill="#F8203D"
            points="165.7,56.1 146.4,52.3 146.4,83.6 165.7,83.2 	"
          />
          <polygon
            className="gift-box-st7"
            fill="#E61E38"
            points="146.4,83.6 146.4,168.6 165.7,163.9 165.7,83.2 	"
          />
          <polygon
            className="gift-box-st8"
            fill="rgba(0,0,0,.2)"
            points="23.9,84 131.7,84 174.8,83 174.8,85.5 131.7,89.5 23.9,86.7 	"
          />
          <polygon
            className="gift-box-st9"
            fill="rgba(255,255,255,.2)"
            points="19.6,56.1 131.7,49.4 180.4,59 180.4,60 131.7,54 19.6,57.6 "
          />
        </g>
      </g>
      <ellipse
        className="gift-shadow"
        fill="rgba(0,0,0,.2)"
        cx="100"
        cy="185.3"
        rx="92.7"
        ry="10.9"
      />
    </svg>
  )
}

export default GiftBoxAnimation
