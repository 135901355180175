import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Icons from '../components/Icons'
import Header from './layout/Header'
import FooterAlt from './layout/FooterAlt'
import PopUpsAndChat from './layout/PopUpsAndChat'
import '../styles/main.scss'
import IconsProductPreloader from './products/IconsProductPreloader'

const Layout = ({ children, breadcrumbs, className, shop, title, canonical, h1 = '', submenu }) => {
  const [navbarOpen, setNavbarOpen] = useState(null)
  return (
    <>
      <Icons />
      <IconsProductPreloader />
      <Header
        breadcrumbs={breadcrumbs}
        canonical={canonical}
        shop={shop}
        title={title}
        navbarOpen={navbarOpen}
        setNavbarOpen={setNavbarOpen}
        h1={h1}
      >
        {submenu}
      </Header>
      <main className={navbarOpen ? 'hidden lg:block' : className || ' mx-auto overflow-x-hidden'}>
        {children}
      </main>

      <FooterAlt className={navbarOpen ? 'hidden lg:block print:hidden' : 'print:hidden'} />

      <PopUpsAndChat navbarOpen={navbarOpen} className="print:hidden" />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  h1: PropTypes.string,
  breadcrumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  ]),
  className: PropTypes.string,
  canonical: PropTypes.string,
  title: PropTypes.string,
  shop: PropTypes.bool,
  submenu: PropTypes.node,
}

Layout.defaultProps = {
  children: null,
  className: null,
  breadcrumbs: false,
  canonical: null,
  shop: false,
  title: null,
  submenu: null,
}

export default Layout
